import { forwardRef, type ChangeEvent, type KeyboardEvent } from 'react'
import { Box, IconButton, Input } from '@chakra-ui/react'
import { VscSend } from 'react-icons/vsc'

interface ChatTextInputProps {
  onUpdate: (text: string) => void
  onSend: (text: string) => Promise<void>
  value: string
  isLoading: boolean
  isInit: boolean
}

const ChatTextInput = forwardRef<HTMLInputElement, ChatTextInputProps>((props, ref) => {
  const { onUpdate, onSend, value, isLoading, isInit } = props

  return (
    <Box w="full">
      <Box position="relative" w="full">
        <Input
          className="ai-widget__chat-input"
          bg="background.default"
          borderRadius={4}
          errorBorderColor="status.error"
          name="chat-input"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onUpdate(e.target.value)
          }}
          onKeyDown={(event: KeyboardEvent) => {
            if (event.key === 'Enter') {
              void onSend(value)
            }
          }}
          placeholder="Reply"
          pr="40px"
          ref={ref}
          value={value}
          variant="outline"
        />

        <IconButton
          aria-label="Send message"
          borderRadius="full"
          icon={<VscSend />}
          isDisabled={!value?.length || isLoading || !isInit}
          onClick={() => void onSend(value)}
          position="absolute"
          right={2}
          size="xs"
          top="50%"
          transform="translateY(-50%)"
          zIndex={2}
        />
      </Box>
    </Box>
  )
})

ChatTextInput.displayName = 'ChatTextInput'

export default ChatTextInput
