import 'regenerator-runtime/runtime'
import { useCallback, useEffect, useState } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import useTimeout from './useTimeout'

interface UseVoiceAssistantParams {
  onResult: (text: string) => Promise<void>
  onError: (error: string) => Promise<void>
  onNoVoice?: () => Promise<void>
}

const NO_VOICE_TIMEOUT = 2500

const useVoiceAssistant = ({ onResult, onError, onNoVoice }: UseVoiceAssistantParams) => {
  const [isRecording, setIsRecording] = useState(false)
  const [lastTranscript, setLastTranscript] = useState<string | null>(null)
  const [interimTranscript, setInterimTranscript] = useState<string | null>(null)

  const {
    finalTranscript,
    interimTranscript: currentInterimTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable
  } = useSpeechRecognition()

  const userLanguage = navigator.language || 'en-US'

  const voiceDictionary = useCallback((availableVoices: SpeechSynthesisVoice[]) => {
    const voices: Record<string, SpeechSynthesisVoice[]> = {
      'sv-SE': availableVoices.filter((voice) => voice.lang === 'sv-SE'),
      'en-US': availableVoices.filter((voice) => voice.lang === 'en-US'),
      'en-GB': availableVoices.filter((voice) => voice.lang === 'en-GB')
    }
    return voices
  }, [])

  useEffect(() => {
    const handleBrowserSupport = async () => {
      if (!browserSupportsSpeechRecognition) {
        await onError('Your browser does not support speech recognition.')
      } else if (!isMicrophoneAvailable) {
        await onError('Microphone access denied. Please enable it in your browser settings.')
      }
    }
    void handleBrowserSupport()
  }, [
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    onError,
    userLanguage,
    voiceDictionary
  ])

  const startAudioRecording = useCallback(async () => {
    if (!listening) {
      try {
        setIsRecording(true)
        setLastTranscript(null)
        setInterimTranscript(null)
        await SpeechRecognition.startListening({
          continuous: false,
          interimResults: true,
          language: userLanguage
        })
      } catch (error) {
        setIsRecording(false)
        await onError(`Failed to start speech recognition. ${error}`)
      }
    }
  }, [listening, onError, userLanguage])

  const stopAudioRecording = useCallback(async () => {
    if (listening) {
      try {
        setIsRecording(false)
        await SpeechRecognition.stopListening()
      } catch (error) {
        await onError(`Failed to stop speech recognition. ${error}`)
      }
    }
  }, [listening, onError])

  useEffect(() => {
    const handleFinalTranscript = async () => {
      try {
        if (finalTranscript && finalTranscript !== lastTranscript) {
          setLastTranscript(finalTranscript)
          await onResult(finalTranscript)
          await stopAudioRecording()
          resetTranscript()
        }
      } catch (error) {
        await onError('An error occurred while processing the transcript.')
      }
    }

    void handleFinalTranscript()
  }, [finalTranscript, lastTranscript, onResult, resetTranscript, stopAudioRecording, onError])

  useEffect(() => {
    if (currentInterimTranscript && isRecording) {
      setInterimTranscript(currentInterimTranscript)
    }
  }, [currentInterimTranscript, isRecording])

  useTimeout(
    () => {
      if (isRecording && !interimTranscript && !finalTranscript) {
        stopAudioRecording().catch((error: unknown) =>
          onError(`Failed to stop recording after no voice detected. ${error}`)
        )
        onNoVoice?.().catch((error: unknown) =>
          onError(`Failed to handle no voice detected. ${error}`)
        )
      }
    },
    isRecording ? NO_VOICE_TIMEOUT : null
  )

  return {
    isRecording: listening && isRecording,
    startAudioRecording,
    stopAudioRecording,
    isSpeechRecognitionSupported: browserSupportsSpeechRecognition
  }
}

export default useVoiceAssistant
