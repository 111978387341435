import { type FC, type ReactNode } from 'react'
import { Box, Center, Flex, HStack, Icon, type StackProps, VStack } from '@chakra-ui/react'
import type { AssistantMessageFE, AssistantMessageProductDTO } from 'ecosystem'
import { IoMdAlert } from 'react-icons/io'
import GrandText from 'ui/common/GrandText'
import GrandTooltip from 'ui/common/GrandTooltip'
import { PiSpeakerSimpleHighDuotone } from 'react-icons/pi'
import dynamic from 'next/dynamic'
import { useAssistantContext } from './asssistantContext'
import { Avatar } from './Avatar'
import useSpeechAnimationSrc from './useSpeechAnimationSrc'

const LottiePlayer = dynamic(
  () => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player),
  {
    ssr: false
  }
)

export interface ChatMessageProps extends StackProps {
  msg: AssistantMessageFE
  renderProductsComponent: (products: AssistantMessageProductDTO[] | undefined) => ReactNode
  onSpeak: (text: AssistantMessageFE) => Promise<void> | void
  isLoadingSpeech: boolean
  isPlayingSpeech: boolean
  error?: string
}

const ChatMessage: FC<ChatMessageProps> = ({
  onSpeak,
  renderProductsComponent,
  msg,
  error,
  isLoadingSpeech,
  isPlayingSpeech,
  ...props
}) => {
  const { settings } = useAssistantContext()
  const isBot = msg.role === 'assistant'

  const animationSrc = useSpeechAnimationSrc({
    isLoading: isLoadingSpeech,
    isPlayingThumb: isPlayingSpeech
  })

  const renderSpeechIcon = () => {
    if (animationSrc) {
      return (
        <Box boxSize={6}>
          <LottiePlayer
            autoplay
            loop
            src={animationSrc}
            style={{ height: '100%', width: '100%' }}
          />
        </Box>
      )
    }

    return (
      <Icon
        className="ai-widget__message-speak"
        cursor="pointer"
        flexShrink={1}
        as={PiSpeakerSimpleHighDuotone}
        boxSize={6}
        color="grey"
        onClick={() => void onSpeak(msg)}
      />
    )
  }

  if (isBot) {
    return (
      <HStack
        alignItems="flex-start"
        alignSelf="start"
        className="ai-widget__message"
        data-message-id={msg.id}
        gap={1}
        maxWidth="100%"
        width="full"
        {...props}>
        <Center borderRadius="50%" position="sticky" top={0}>
          <Avatar w="20px" h="20px" imageProps={{ src: settings?.imgUrl }} />
        </Center>

        <VStack alignItems="flex-start" flex={1}>
          <GrandText
            bg="background.surface"
            borderRadius={6}
            fontSize="sm"
            fontWeight="normal"
            px={4}
            py={2}
            shadow="md"
            w="auto"
            whiteSpace="break-spaces">
            {msg.text}
          </GrandText>
          {renderProductsComponent(msg.products)}
        </VStack>

        {renderSpeechIcon()}
      </HStack>
    )
  }

  return (
    <HStack alignSelf="end" maxWidth="calc(90% - 45px)" {...props}>
      {!!error && (
        <GrandTooltip color="red" label={error}>
          <Box color="red">
            <IoMdAlert />
          </Box>
        </GrandTooltip>
      )}

      <Flex bg="#5ba2c0" borderRadius={6} color="text.light" px={4} py={2} shadow="md">
        <GrandText fontSize="sm" fontWeight="normal" w="full">
          {msg.text}
        </GrandText>
      </Flex>
    </HStack>
  )
}

export default ChatMessage
