import { useCallback, useEffect, useMemo, useState } from 'react'
import { type AssistantMessageFE } from 'ecosystem'
import { ReducerActionType } from '../../common/hooks'
import { ConversationalModeStatus } from '../components/types'
import {
  ConversationalModeTransitions,
  transitionStatus
} from '../utils/conversational-mode-state-machine'
import { useAssistantContext } from '../components/asssistantContext'
import useVoiceAssistant from './useVoiceAssistant'
import { useStatusRef } from './useStatusRef'
import { useLastAssistantMessage } from './useLastAssistantMessage'

const useConversationalMode = ({
  onResultError,
  onResult,
  onResponse,
  history
}: {
  onResult: (transcript: string) => Promise<void>
  onResultError: (error: string) => Promise<void>
  onResponse: (
    text: string,
    {
      onAudioStart,
      onAudioEnd
    }: {
      onAudioStart: () => void
      onAudioEnd: () => void
    }
  ) => Promise<void>
  history: AssistantMessageFE[] | null
}) => {
  const { dispatchConversationalMode, conversationalMode } = useAssistantContext()

  const isOperational = useMemo(
    () =>
      conversationalMode.status !== ConversationalModeStatus.Inactive &&
      conversationalMode.status !== ConversationalModeStatus.NotInitialized,
    [conversationalMode.status]
  )

  const isBusy = useMemo(
    () =>
      conversationalMode.status === ConversationalModeStatus.Processing ||
      conversationalMode.status === ConversationalModeStatus.Responding ||
      conversationalMode.status === ConversationalModeStatus.ProcessingResponse,
    [conversationalMode.status]
  )

  const statusRef = useStatusRef(conversationalMode.status)

  const updateStatus = useCallback(
    (transition: ConversationalModeTransitions) => {
      const currentStatus = statusRef.current
      const newStatus = transitionStatus(currentStatus, transition)
      if (newStatus !== currentStatus) {
        dispatchConversationalMode({
          type: ReducerActionType.Set,
          payload: { status: newStatus }
        })
        statusRef.current = newStatus
      }
    },
    [dispatchConversationalMode, statusRef]
  )

  const interceptTranscription = useCallback(
    async (transcript: string) => {
      updateStatus(ConversationalModeTransitions.StartProcessing)
      try {
        await onResult(transcript)
        updateStatus(ConversationalModeTransitions.FinishProcessing)
      } catch (e) {
        await onResultError('Something happened while processing the transcript')
      }
    },
    [onResult, onResultError, updateStatus]
  )

  const interceptTranscriptionError = useCallback(
    async (error: string) => {
      updateStatus(ConversationalModeTransitions.Error)
      await onResultError(error)
    },
    [onResultError, updateStatus]
  )

  const voiceAssistant = useVoiceAssistant({
    onResult: interceptTranscription,
    onError: interceptTranscriptionError,
    onNoVoice: async () => {
      return new Promise<void>((resolve) => {
        updateStatus(ConversationalModeTransitions.Inactivate)
        resolve()
      })
    }
  })
  const { isSpeechRecognitionSupported, stopAudioRecording, startAudioRecording, isRecording } =
    voiceAssistant

  useEffect(() => {
    if (
      isSpeechRecognitionSupported &&
      conversationalMode.status === ConversationalModeStatus.NotInitialized
    ) {
      updateStatus(ConversationalModeTransitions.Initialize)
    } else if (!isSpeechRecognitionSupported) {
      updateStatus(ConversationalModeTransitions.Cleanup)
    }

    dispatchConversationalMode({
      type: ReducerActionType.Set,
      payload: { isSupported: isSpeechRecognitionSupported }
    })
  }, [
    isSpeechRecognitionSupported,
    conversationalMode.status,
    updateStatus,
    dispatchConversationalMode
  ])

  const startRecording = useCallback(async () => {
    await startAudioRecording()
  }, [startAudioRecording])

  const stopRecording = useCallback(async () => {
    await stopAudioRecording()
    updateStatus(ConversationalModeTransitions.Inactivate)
  }, [stopAudioRecording, updateStatus])

  useEffect(() => {
    if (!isOperational) return

    if (isRecording) {
      updateStatus(ConversationalModeTransitions.StartRecording)
    } else {
      updateStatus(ConversationalModeTransitions.FinishRecording)
    }
  }, [isOperational, isRecording, updateStatus])

  useEffect(() => {
    dispatchConversationalMode({
      type: ReducerActionType.Set,
      payload: { isRecording, stopRecording, startRecording }
    })
  }, [dispatchConversationalMode, isRecording, startRecording, stopRecording])

  const [firstInteraction, setFirstInteraction] = useState(false)

  useEffect(() => {
    if (firstInteraction && conversationalMode.status === ConversationalModeStatus.Active) {
      void startRecording()
      setFirstInteraction(false)
    }
  }, [conversationalMode.status, firstInteraction, startRecording])

  const activate = useCallback(() => {
    updateStatus(ConversationalModeTransitions.Activate)
    setFirstInteraction(true)
  }, [updateStatus])

  const deactivate = useCallback(async () => {
    setFirstInteraction(false)
    await stopRecording()
    updateStatus(ConversationalModeTransitions.Inactivate)
  }, [stopRecording, updateStatus])

  const labels = useMemo(() => {
    switch (conversationalMode.status) {
      case ConversationalModeStatus.Recording:
        return 'Listening...'
      case ConversationalModeStatus.Processing:
        return 'Processing...'
      case ConversationalModeStatus.ProcessingResponse:
        return 'Preparing response...'
      case ConversationalModeStatus.Responding:
        return 'Responding...'
      case ConversationalModeStatus.Error:
        return 'Something went wrong...'
      default:
        return ''
    }
  }, [conversationalMode.status])

  const lastAssistantMessage = useLastAssistantMessage(history)

  const shouldReadLastAssistantMessage = useMemo(() => {
    return (
      isOperational &&
      conversationalMode.status === ConversationalModeStatus.ProcessingResponse &&
      (history?.filter((i) => i.role === 'assistant').length || 0) > 1
    )
  }, [isOperational, conversationalMode.status, history])

  const readLastAssistantMessage = useCallback(async () => {
    if (shouldReadLastAssistantMessage) {
      await onResponse(lastAssistantMessage, {
        onAudioStart: () => {
          updateStatus(ConversationalModeTransitions.StartResponding)
        },
        onAudioEnd: () => {
          updateStatus(ConversationalModeTransitions.FinishResponding)
        }
      })
    }
  }, [shouldReadLastAssistantMessage, updateStatus, onResponse, lastAssistantMessage])

  useEffect(() => {
    void readLastAssistantMessage()
  }, [readLastAssistantMessage])

  return {
    ...conversationalMode,
    isOperational,
    activate,
    deactivate,
    labels,
    isBusy,
    updateStatus
  }
}

export default useConversationalMode
