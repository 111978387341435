import { type FC, type ReactNode, useCallback, useEffect } from 'react'
import { Flex, Grid, GridItem, HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react'
import type { AssistantMessageProductDTO, Option } from 'ecosystem'
import dynamic from 'next/dynamic'
import { PiHeadphonesFill } from 'react-icons/pi'
import { CgClose } from 'react-icons/cg'
import { BiError } from 'react-icons/bi'
import useConversationalMode from '../hooks/useConversationalMode'
import { ConversationalModeTransitions } from '../utils/conversational-mode-state-machine'
import ChatMessages from './ChatMessages'
import { useAssistantContext } from './asssistantContext'
import ChatTextInput from './ChatTextInput'
import { ConversationalModeStatus } from './types'
import useSpeechAnimationSrc from './useSpeechAnimationSrc'

const LottiePlayer = dynamic(
  () => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player),
  {
    ssr: false
  }
)

interface ChatProps {
  renderProductsComponent: (products: AssistantMessageProductDTO[] | undefined) => ReactNode
  isExpanded: boolean
  onSpeak: (
    text: string,
    {
      onAudioStart,
      onAudioEnd
    }: {
      onAudioStart: () => void
      onAudioEnd: () => void
    }
  ) => Promise<void>
  onSpeakError: Option<string>
  isLoadingSpeech: boolean
  isPlayingSpeech: boolean
}

const Chat: FC<ChatProps> = ({
  isPlayingSpeech,
  renderProductsComponent,
  onSpeak,
  onSpeakError,
  isLoadingSpeech
}) => {
  const stateUpdater = useAssistantContext()
  const {
    inputValue: value,
    setInputValue,
    isLoading,
    handleSend,
    isInit,
    inputRef,
    history,
    onSpeakAudioRef
  } = stateUpdater

  const sendMessage = useCallback(
    async (text: string) => {
      if (isLoading || !isInit) {
        return
      }

      await handleSend(text)
      setInputValue('')
    },
    [handleSend, isInit, isLoading, setInputValue]
  )

  const onTranscriptSucess = useCallback(
    async (transcript: string) => {
      await sendMessage(transcript)
    },
    [sendMessage]
  )

  const onTranscriptError = useCallback(async (error: string) => {
    await new Promise((resolve) => {
      // eslint-disable-next-line -- Intended log in console
      console.error('Chat error', error)
      resolve(true)
    })
  }, [])

  const { isSupported, status, activate, deactivate, isOperational, labels, isBusy, updateStatus } =
    useConversationalMode({
      onResult: onTranscriptSucess,
      onResultError: onTranscriptError,
      history,
      onResponse: onSpeak
    })

  const animationSrc = useSpeechAnimationSrc({
    isLoading:
      status === ConversationalModeStatus.Processing ||
      status === ConversationalModeStatus.ProcessingResponse,
    isPlaying: status === ConversationalModeStatus.Responding,
    isRecording: status === ConversationalModeStatus.Recording
  })

  useEffect(() => {
    if (onSpeakError) {
      updateStatus(ConversationalModeTransitions.Error)
    }
  }, [onSpeakError, updateStatus])

  return (
    <Grid
      className="ai-widget__chat"
      position="relative"
      gap="1"
      h="full"
      templateColumns="repeat(1, 1fr)"
      templateRows="repeat(12, 1fr)"
      w="full">
      {isOperational ? (
        <VStack p={4} zIndex="modal" w="full" h="full" position="absolute" bg="background.default">
          <VStack flex={1} w="full">
            <Flex alignItems="center" justifyContent="center" w="full" flex={1}>
              {animationSrc ? (
                <LottiePlayer
                  autoplay
                  loop
                  src={animationSrc}
                  style={{ height: '100%', width: '70%' }}
                />
              ) : null}
              {status === ConversationalModeStatus.Error ? (
                <Icon as={BiError} p={10} h="80%" w="80%" color="red.200" />
              ) : null}
            </Flex>
            <Text
              as={Flex}
              alignItems="center"
              p={2}
              fontWeight="medium"
              fontSize="lg"
              color="gray.500">
              {labels}
            </Text>
          </VStack>
          <HStack w="full" justify="flex-end">
            <IconButton
              id="voice-assistant-close"
              aria-label="Close"
              borderRadius="full"
              colorScheme="red"
              isDisabled={isBusy}
              onClick={() => void deactivate()}
              icon={<Icon as={CgClose} boxSize={5} />}
              size="lg"
            />
          </HStack>
        </VStack>
      ) : null}

      <GridItem colSpan={1} rowSpan={11}>
        <ChatMessages
          isPlayingSpeech={isPlayingSpeech}
          isLoadingSpeech={isLoadingSpeech}
          onSpeak={async (text) =>
            await onSpeak(text, {
              onAudioEnd: () => {
                // no implementation
              },
              onAudioStart: () => {
                // no implementation
              }
            })
          }
          renderProductsComponent={renderProductsComponent}
          h="full"
          w="full"
        />
      </GridItem>

      <GridItem pb={4} pt={2} px={2} as={HStack} align="center" colSpan={1} rowSpan={1}>
        <ChatTextInput
          ref={inputRef}
          onUpdate={setInputValue}
          onSend={sendMessage}
          value={value}
          isLoading={isLoading}
          isInit={isInit}
        />

        {isSupported ? (
          <IconButton
            isDisabled={status === ConversationalModeStatus.NotInitialized}
            id="voice-assistant-trigger"
            aria-label="Voice Assistant"
            borderRadius="full"
            onClick={() => {
              onSpeakAudioRef?.current?.pause()
              activate()
            }}
            icon={<PiHeadphonesFill />}
            size="sm"
            zIndex={2}
          />
        ) : null}
      </GridItem>
    </Grid>
  )
}

export default Chat
